import { AUTH_SESSION, AuthActions, AuthState, LOGOUT } from './types';

const initialState: AuthState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    uiLanguage: '',
    communicationLanguage: '',
    accountType: '',
    uom: '',
    dateFormat: '',
    timeFormat: '',
    emailDay: [],
  },
  authTicket: {
    token: '',
    expires: 0,
    duration: 0,
  },
};

export const authReducer = (state = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AUTH_SESSION:
      return {
        ...state,
        ...action.authSession,
      };
    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
