import React, { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';

import { ApiStatus } from 'Enums';

import { useApiError, useAuth } from 'Hooks';

import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import { setUrls } from 'Utilities/dataHelpers';

import { forgotPass } from 'Services/auth';

import { RootState } from 'src/reducers';

import ForgotPassFirstStep from './steps/ForgotPassFirstStep';
import ForgotPassSecondStep from './steps/ForgotPassSecondStep';

const mapStateToProps = ({ env: { app, pId }, config }: RootState) => {
  return {
    app,
    pId,
    config,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const ForgotPasswordPage: React.FC<Props> = ({ app, pId, config }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState<{ email: string }>({
    email: '',
  });

  const { showApiErrorModal } = useApiError();

  const {
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showCountryRegionError,
  } = useAuth();

  const handleWrongRegionError = useCallback(
    (error: AxiosError<ApiErrorData>, handleSubmit: () => void) => {
      const unsupportedCountry = error.response?.data.errors[0]?.unsupportedCountry;

      if (!unsupportedCountry) {
        showApiErrorModal();
        return;
      }

      setUrls(config, unsupportedCountry);

      handleSubmit();
    },
    [config, showApiErrorModal]
  );

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>, handleSubmit: () => void) => {
      switch (error.status) {
        case ApiStatus.FORBIDDEN: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
          }

          break;
        }

        case ApiStatus.BAD_REQUEST: {
          const code = error.response?.data.code;

          if (code === ApiStatus.UNSUPPORTED_COUNTRY) {
            showCountryRegionError();
            return;
          }

          if (code === ApiStatus.WRONG_REGION) {
            handleWrongRegionError(error, handleSubmit);
            return;
          }

          showApiErrorModal();
          break;
        }

        case ApiStatus.TOO_MANY_REQUESTS:
          showForgotPasswordBlockedMessage();
          break;
        default:
          showApiErrorModal();
      }
    },
    [
      handleWrongRegionError,
      showApiErrorModal,
      showForgotPasswordBlockedMessage,
      showHcpError,
      showCountryRegionError,
    ]
  );

  const handleSubmitForgotPass = useCallback(
    (email: string, submitForm: () => void) => {
      return new Promise<void>((resolve, reject) => {
        setLoading(true);
        forgotPass(email, app, pId)
          .then(resolve)
          .catch((error: AxiosError<ApiErrorData>) => {
            handleApiRequestError(error, submitForm);
            reject(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [app, handleApiRequestError, pId]
  );

  const makeSteps = useMemo(() => {
    return [
      {
        component: ForgotPassFirstStep,
        componentName: 'ForgotPassFirstStep',
      },
      {
        component: ForgotPassSecondStep,
        componentName: 'ForgotPassSecondStep',
      },
    ];
  }, []);

  return (
    <React.Fragment>
      <WizardMainContainer>
        <Wizard
          steps={makeSteps}
          initialValues={initialValues}
          handleSubmitForgotPass={handleSubmitForgotPass}
          showForgotPasswordConfirmatioMessage={showForgotPasswordConfirmatioMessage}
          loading={loading}
        />
      </WizardMainContainer>
    </React.Fragment>
  );
};

export default connector(ForgotPasswordPage);
