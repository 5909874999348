export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface LanguageState {
  language: string;
}

interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: string;
}

export type LanguageActions = SetLanguageAction;
