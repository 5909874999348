import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Text, useToast } from '@adc/parallax-component-library';
import { IconValidationValid } from '@adc/parallax-icons';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { LLUInvitationStatus } from 'Enums';

import { useApiError, useAuth } from 'Hooks';

import {
  Footer,
  Form,
  Input,
  Main,
  ScrollContainer,
  Spinner,
  TitleActionBar,
} from 'Components/utility';

import { RootState } from 'Reducers/index';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

import { addConnection } from 'Services/llu';

interface Connection {
  firstName: string;
  lastName: string;
  email: string;
}

const initialValues: Connection = {
  firstName: '',
  lastName: '',
  email: '',
};

const mapStateToProps = ({ auth, config }: RootState) => {
  return {
    auth,
    regions: config.regions ?? {},
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const LLUAddConnection: React.FC<Props> = ({ auth, regions }) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const { showHcpError } = useAuth();

  const { showToast } = useToast();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      i18n.t<string>(
        'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.errors.required'
      )
    ),
    lastName: Yup.string().required(
      i18n.t<string>(
        'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.errors.required'
      )
    ),
    email: Yup.string()
      .email(
        i18n.t<string>('Login.content.signInForm.formField.email.errors.emailAddressValidEmail')
      )
      .required(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.email.errors.emailAddressValidEmail'
        )
      ),
  });

  const onSubmit = async (values: Connection, { setFieldError }: FormikHelpers<Connection>) => {
    try {
      setLoading(true);

      const { status, pendingConnectionInvitation } = await addConnection(
        values.firstName,
        values.lastName,
        values.email,
        auth,
        regions
      );

      switch (status) {
        case LLUInvitationStatus.PENDING: {
          if (pendingConnectionInvitation) {
            mediator.publish('router:navigate', {
              url: '/llu-pending-connection',
              data: { pendingConnectionInvitation },
            });

            showToast({
              message: i18n.t('Connections.modals.invitationSentToast.body'),
              'aria-label': i18n.t('Connections.modals.invitationSentToast.body'),
              icon: <IconValidationValid color="$support.positive.default" />,
            });
          }

          break;
        }

        case LLUInvitationStatus.INVALID_REGION:
          setFieldError(
            'email',
            i18n.t('Connections.content.addConnectionForm.formField.email.errors.locationError')
          );
          break;

        case LLUInvitationStatus.HCP_REJECTED:
          showHcpError();
          break;

        case LLUInvitationStatus.ALREADY_SENT:
          setFieldError('email', 'Connections.modals.invitationAlreadySent.body');
          break;

        default:
          showApiErrorModal();
      }
    } catch {
      showApiErrorModal();
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, handleBlur, isValid, errors, touched, values } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
  });

  return (
    <Main>
      <TitleActionBar
        testID="AddConnection"
        title={i18n.t('Connections.content.addConnectionForm.primaryText')}
        closeBtn
      />
      {loading && <Spinner testID="Login" />}
      <ScrollContainer marginTop={0}>
        <Form>
          <Text
            testID="AddConnection.secondaryText"
            fontSize="$bodyBase.default"
            fontWeight="$bodyBase.default"
            color="$text.100"
            marginBottom="$4"
          >
            {i18n.t<string>('Connections.content.addConnectionForm.secondaryText')}
          </Text>
          <Input
            aria-label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.firstName.label'
            )}
            placeholder={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.firstName.placeholder'
            )}
            label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.firstName.label'
            )}
            testID="firstName"
            inputMode="text"
            errorMessage={errors.firstName}
            isInvalid={errors.firstName && touched.firstName ? true : false}
            value={values.firstName}
            onChangeText={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
          />
          <Input
            aria-label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.lastName.label'
            )}
            placeholder={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.lastName.placeholder'
            )}
            label={i18n.t<string>('Connections.content.addConnectionForm.formField.lastName.label')}
            testID="lastName"
            inputMode="text"
            errorMessage={errors.lastName}
            isInvalid={errors.lastName && touched.lastName ? true : false}
            value={values.lastName}
            onChangeText={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
          />
          <Input
            aria-label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.email.label'
            )}
            placeholder={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.email.placeholder'
            )}
            label={i18n.t<string>('Connections.content.addConnectionForm.formField.email.label')}
            testID="email"
            inputMode="text"
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            value={values.email}
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
          />
          <Text
            testID="AddConnection.body"
            fontSize="$bodySmall.default"
            fontWeight="$bodySmall.default"
            color="$text.60"
            marginTop="$2"
          >
            {i18n.t<string>('Connections.content.addConnectionForm.bodyText')}
          </Text>
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'Connections.content.addConnectionForm.links.inviteConnection.label'
        )}
        onButtonSubmit={handleSubmit}
        testIDButton="AddConnection.inviteConnection-btn"
        isButtonDisabled={!values.firstName || !values.lastName || !values.email || !isValid}
      />
    </Main>
  );
};

export default connector(LLUAddConnection);
