import { LanguageActions, LanguageState, SET_LANGUAGE } from './types';

const initialState: LanguageState = {
  language: '',
};

export const languageReducer = (state = initialState, action: LanguageActions): LanguageState => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        language: action.language,
      };
    default:
      return state;
  }
};
