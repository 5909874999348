import { ApiStatus, LLUInvitationStatus } from 'Enums';

import { urls } from 'Utilities/dataHelpers';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const getConnections = () => {
  return new Promise<LLUConnection[]>((resolve, reject) => {
    mediator
      .request<ApiResponse<LLUDataResponse<LLUConnection[]>>>('api:invoke', {
        method: 'GET',
        url: urls.lvapiCountryURL + 'sharing/llu/connections',
      })
      .then((result) => {
        if (result.data && result.data.status === ApiStatus.SUCCESS) {
          const { data: connections } = result.data;

          if (connections) {
            return resolve(connections);
          }
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const getPendingConnections = (senderId: string) => {
  return new Promise<LLUPendingConnectionInvitation[]>((resolve, reject) => {
    mediator
      .request<ApiResponse<LLUDataResponse<LLUPendingConnectionInvitation[]>>>('api:invoke', {
        method: 'GET',
        url: urls.lvapiCountryURL + 'sharing/llu/invitations/sender/' + senderId,
      })
      .then((result) => {
        if (result.data && result.data.status === ApiStatus.SUCCESS) {
          const { data: invitations } = result.data;

          if (invitations) {
            return resolve(invitations);
          }
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const addConnection = (
  firstName: string,
  lastName: string,
  email: string,
  authSession: AuthSession,
  regions: Regions
) => {
  const { user } = authSession;

  const regionEntry = Object.entries(regions).find(([, { countries }]) =>
    countries.includes(user.country)
  );

  const senderRegion = regionEntry ? regionEntry[0] : '';

  return new Promise<{
    status: LLUInvitationStatus;
    pendingConnectionInvitation?: LLUPendingConnectionInvitation;
  }>((resolve, reject) => {
    mediator
      .request<ApiResponse<LLUDataResponse<LLUPendingConnectionInvitation>>>('api:invoke', {
        method: 'POST',
        url: urls.lvapiCountryURL + 'sharing/llu/invitations',
        data: {
          InvitationType: 'Adult',
          Product: 'LibreLinkUp',
          GatewayType: 'LL-Webviews',
          ReceiverEmail: email,
          SenderAccountId: user.id,
          SenderCountry: user.country,
          SenderRegion: senderRegion,
          InvitationDetails: {
            ReceiverFirstName: firstName,
            ReceiverLastName: lastName,
            SenderCulture: user.uiLanguage,
            SenderFirstName: user.firstName,
            SenderLastNAme: user.lastName,
          },
        },
      })
      .then((result) => {
        const { data } = result;

        if (!data) {
          reject();
          return;
        }

        const { status } = data;

        if (status === LLUInvitationStatus.PENDING) {
          const { data: pendingConnectionInvitation } = data;

          resolve({ status, pendingConnectionInvitation });
          return;
        }

        if (
          [
            LLUInvitationStatus.INVALID_REGION,
            LLUInvitationStatus.HCP_REJECTED,
            LLUInvitationStatus.ALREADY_SENT,
          ].includes(status)
        ) {
          resolve({ status });
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const removeConnection = (connectionId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<LLUDataResponse>>('api:invoke', {
        method: 'DELETE',
        url: urls.lvapiCountryURL + 'sharing/llu/connections/' + connectionId,
      })
      .then((result) => {
        if (result.data && result.data.status === ApiStatus.SUCCESS) {
          resolve();
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const removeInvitation = (invitationId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<LLUDataResponse>>('api:invoke', {
        method: 'DELETE',
        url: urls.lvapiCountryURL + 'sharing/llu/invitations/' + invitationId,
      })
      .then((result) => {
        if (result.data && result.data.status === ApiStatus.SUCCESS) {
          resolve();
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const resendInvitation = (invitationId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<LLUDataResponse>>('api:invoke', {
        method: 'GET',
        url: urls.lvapiCountryURL + 'sharing/llu/invitations/' + invitationId + '/resend',
      })
      .then((result) => {
        if (result.data && result.data.status === ApiStatus.SUCCESS) {
          resolve();
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
