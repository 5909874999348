import React from 'react';

import { HStack, Text, VStack } from '@adc/parallax-component-library';
import { IconBack, IconClose } from '@adc/parallax-icons';

import { IconButton } from 'Components/utility';

import mediator from 'Utilities/mediator';

import { ActionBarHeader } from './ActionBarHeader';

type TitleActionBarProps = {
  testID?: string;
  title: string;
  closeBtn?: boolean;
  onBack?: () => void;
};

const TitleActionBar: React.FC<TitleActionBarProps> = ({ testID, title, closeBtn, onBack }) => {
  return (
    <VStack
      style={{
        top: 0,
        left: 0,
        right: 0,
      }}
      backgroundColor="$surface.container"
    >
      <ActionBarHeader testID={testID} />
      <HStack padding="$4">
        <IconButton
          testID={`${testID}.back-btn`}
          onPress={() => {
            onBack ? onBack() : mediator.publish('router:back');
          }}
        >
          {closeBtn ? (
            <IconClose testID={`${testID}.close-btn-icon`} />
          ) : (
            <IconBack testID={`${testID}.back-btn-icon`} />
          )}
        </IconButton>
        <Text
          testID={`${testID}.title`}
          color="$text.100"
          fontSize="$h4.strong"
          fontWeight="$h4.strong"
          width="100%"
          alignSelf="center"
          textAlign="center"
          numberOfLines={1}
        >
          {title}
        </Text>
      </HStack>
    </VStack>
  );
};

export { TitleActionBar };
