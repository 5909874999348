import { fromUnixTime } from 'date-fns';

export const setObject = <T>(key: string, value: T) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getObject = <T>(key: string): T | null => {
  const value = window.localStorage.getItem(key);

  return value && JSON.parse(value);
};

export const removeObject = (key: string) => {
  window.localStorage.removeItem(key);
};

export const setSessionObject = <T>(key: string, value: T): void => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionObject = <T>(key: string): T | null => {
  const value = window.sessionStorage.getItem(key);

  return value && JSON.parse(value);
};

export const removeSessionObject = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const clearSession = () => {
  window.sessionStorage.clear();
};

export const getCookie = <T>(key: string): T | null => {
  const nameEQ = key + '=';
  const ca = window.document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      const value = c.substring(nameEQ.length, c.length);

      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    }
  }

  return null;
};

export const setCookie = <T>(key: string, value: T, time: number): void => {
  let expires = '';

  if (time) {
    const date = fromUnixTime(time);

    expires = '; expires=' + date.toUTCString();
  }

  const jsonValue = JSON.stringify(value);

  window.document.cookie = key + '=' + (jsonValue || '') + expires + '; path=/';
};

export const clearCookie = (key: string): void => {
  window.document.cookie = key + '=; Max-Age=-99999999;';
};

export default {
  clearSession,
  getObject,
  getSessionObject,
  removeObject,
  removeSessionObject,
  setObject,
  setSessionObject,
  getCookie,
  setCookie,
};
