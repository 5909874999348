export const SET_PHONE_LANGUAGE = 'SET_PHONE_LANGUAGE';
export const SET_ENV = 'ENV';

export interface EnvState {
  osType: 'android' | 'iOS' | 'none';
  osVersion: string;
  appVersion: string;
  deviceId: string;
  theme: 'polarisLight' | 'polarisDark' | 'otcLight' | 'otcDark';
  app: string;
  deviceManufacturer: string;
  deviceModel: string;
  country: string;
  pId: string;
}

interface EnvAction {
  type: typeof SET_ENV;
  env: Partial<EnvState>;
}

export type EnvActions = EnvAction;
