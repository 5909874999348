import React from 'react';

import { LogoActionBar, Main, ScrollContainer } from 'Components/utility';

import LLUCardConnection from './LLUCardConnection';

const MainConnection = () => {
  return (
    <Main>
      <LogoActionBar testID="Connections" />
      <ScrollContainer>
        <LLUCardConnection />
      </ScrollContainer>
    </Main>
  );
};

export default MainConnection;
