import { getTheme } from 'Utilities/dataHelpers';

import { NAVIGATION, NavigationAction } from '../nav/types';
import { EnvActions, EnvState, SET_ENV } from './types';

const initialState: EnvState = {
  osType: 'none',
  osVersion: 'none',
  appVersion: 'none',
  deviceId: '',
  theme: getTheme(),
  app: 'none',
  deviceManufacturer: 'none',
  deviceModel: 'none',
  country: 'US',
  pId: '',
};

export const envReducer = (
  state = initialState,
  action: NavigationAction | EnvActions
): EnvState => {
  switch (action.type) {
    case NAVIGATION:
      return {
        ...state,
        app: action.context.query?.app || state.app,
        country: action.context.query?.country || state.country,
        pId: action.context.query?.pId || state.pId,
      };
    case SET_ENV:
      return {
        ...state,
        ...action.env,
      };
    default:
      return state;
  }
};
